import React, { useEffect, useState } from "react";
import style from "./Planning.module.css";
import Tooltip from "@mui/material/Tooltip";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../Url";
import { HiOutlineViewGrid } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";

const Planning = () => {
  const navigate = useNavigate();
  const key = JSON.parse(localStorage.getItem("access-token"));
  const userId = localStorage.getItem("adminUserId");

  const [selectedOrg, setSelectedOrg] = useState("");
  const [orgData, setOrgData] = useState([]);
  const [sessionData, setSessionData] = useState([]);
  const [sessionId, setSessionId] = useState("");
  const [sessionDeleteShow, setSessionDeleteShow] = useState(false);

  async function orgGet() {
    await fetch(`${baseUrl}/org/get/admin/per_users`, {
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "orgData");
        setOrgData(data.data);
      });
  }

  async function sessionGet() {
    await fetch(`${baseUrl}/sessions/list-all`, {
      method: "POST",
      body: JSON.stringify({
        org_id: selectedOrg,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data);
      });
  }

  async function sessionDelete() {
    await fetch(`${baseUrl}/session/delete`, {
      method: "POST",
      body: JSON.stringify({
        session_id: sessionId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setSessionDeleteShow(false);
          sessionGet()
        }
      });
  }

  useEffect(() => {
    if (userId) {
      orgGet();
    }
  }, [userId]);

  useEffect(() => {
    if (selectedOrg) {
      sessionGet();
    }
  }, [selectedOrg]);

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.headerText}>
          <p>Session Planning</p>
        </div>
        <div className={style.headerButton}>
          <button
            onClick={() => {
              navigate("/planning/creation");
            }}
          >
            Create Session Plan
          </button>
        </div>
      </div>
      <div className={style.secondHead}>
        <div className={style.select_section}>
          <div className={style.Org_Select}>
            <FormControl sx={{ width: "350px" }}>
              <InputLabel id="demo-simple-select-label">
                Select Organization
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Organisations"
                value={selectedOrg}
                onChange={(event) => {
                  const selectedOrgID = event.target.value;
                  setSelectedOrg(selectedOrgID);
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300, 
                    },
                  },
                }}
              >
                {orgData && orgData.length > 0 ? (
                  orgData.map((org) => (
                    <MenuItem key={org.org_id} value={org.org_id}>
                      {org.org_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No organisations available</MenuItem>
                )}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div className={style.planningSection}>
        <div className={style.gridList}>
          {sessionData &&
            sessionData.length > 0 &&
            sessionData.map((list, i) => {
              return (
                <div className={style.sessionCard} key={i}>
                  <div className={style.cardTop}>
                    <p>{list.session_name}</p>
                  </div>
                  <div className={style.cardBottom}>
                    <Tooltip title="View">
                      <IconButton>
                        <HiOutlineViewGrid
                          className={style.viewButton}
                          onClick={() => {
                            navigate("/event/list", {
                              state: { session: list.session_id },
                            });
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                    {/* <Tooltip title="Edit">
                      <IconButton>
                        <BiEdit className={style.editButton} />
                      </IconButton>
                    </Tooltip> */}
                    <Tooltip title="Delete">
                      <IconButton>
                        <RiDeleteBinLine
                          className={style.deleteButton}
                          onClick={() => {
                            setSessionId(list.session_id);
                            setSessionDeleteShow(true);
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {sessionDeleteShow && (
        <>
          <div
            onClick={() => {
              setSessionDeleteShow(false);
            }}
            className={style.overlay}
          ></div>
          <div className={style.deletePopup}>
            <div className={style.deleteClose}>
              <AiOutlineClose
                onClick={() => {
                  setSessionDeleteShow(false);
                }}
              />
            </div>
            <div className={style.deleteSection}>
              <div className={style.popupText}>
                <p>Are you sure you want to delete ?</p>
              </div>
              <div className={style.popupDelete}>
                <button
                  className={style.orgDelete}
                  onClick={() => {
                    sessionDelete();
                  }}
                >
                  Delete
                </button>
                <button
                  className={style.orgDeleteCancel}
                  onClick={() => {
                    setSessionDeleteShow(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Planning;
