import React, { useEffect, useState,useRef } from "react";
import style from "./Configuration.module.css";
import { baseUrl } from "../Url";
import { IoCloseOutline } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";

const Configuration = () => {
  const key = JSON.parse(localStorage.getItem("access-token"));

  const [selectedTab, setSelectedTab] = useState(0);
  const [learningLevelData, setLearningLevelData] = useState([
    {
      measure: "",
      condition: "",
      parameter: [],
      then: "",
      operator: "",
      isDisabled: false,
    },
  ]);
  const [learningPaceData, setLearningPaceData] = useState([
    {
      course_name: "",
      course_id: "",
      module: "",
      module_id: "",
      measure: "",
      condition: "",
      parameter: [],
      then: "",
      operator: "",
      isDisabled: false,
      modules: [],
    },
  ]);

  const [performanceData, setPerformanceData] = useState([
    {
      measure: "",
      condition: "",
      parameter: [],
      then: "",
      operator: "",
      isDisabled: false,
    },
  ]);
  const [topicData, setTopicData] = useState([
    {
      measure: "",
      condition: "",
      parameter: [],
      then: "",
      badge_name:"",
      operator: "",
      isDisabled: false,
    },
  ]);
  const [dailyData, setDailyData] = useState([
    {
      measure: "",
      condition: "",
      parameter: [],
      then: "",
      badge_name:"",
      operator: "",
      isDisabled: false,
    },
  ]);
  const [weaklyData, setWeaklyData] = useState([
    {
      measure: "",
      condition: "",
      parameter: [],
      then: "",
      badge_name:"",
      operator: "",
      isDisabled: false,
    },
  ]);
  const [ruleData, setRuleData] = useState([]);
  const [learningLevelSessionID, setLearningLevelSessionID] = useState("");
  const [learningPaceSessionID, setLearningPaceSessionID] = useState("");
  const [performanceSessionID, setPerformanceSessionID] = useState("");
  const [topicSessionID, setTopicSessionID] = useState("");
  const [dailySessionID, setDailySessionID] = useState("");
  const [weaklySessionID, setWeaklySessionID] = useState("");
  const [createPopup, setCreatePopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [createFields, setCreateFields] = useState({
    name: "",
    description: "",
    type: "",
    default_img: "",
    active_img: "",
  });
  const [categoryList, setCategoryList] = useState([]);
  const [badgeList, setBadgeList] = useState([]);
  const [orgList, setOrgList] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState("");
  const [showContent, setShowContent] = useState(true);
  const [editFields,setEditFields]=useState({})
  const [deleteBadgeID,setDeleteBadgeID]=useState("")

  const fileInputRef = useRef(null);
  const editFileInputRef = useRef(null);

  const adminStatus = localStorage.getItem("adminStatus");
  const userRole = localStorage.getItem("userRole");
  const { profile } = useSelector((state) => state.profileData);

  //learning level functions

  const handleInputChange = (index, field, value) => {
    const updatedSections = [...learningLevelData];
    updatedSections[index][field] = value;
    if (field === "condition") {
      if (value === "Between") {
        updatedSections[index].parameter = [
          updatedSections[index].parameter[0] || "",
          "",
        ];
      } else {
        updatedSections[index].parameter = [
          updatedSections[index].parameter[0] || "",
        ];
      }
    }

    setLearningLevelData(updatedSections);
  };

  const handleValuesChange = (index, valueIndex, value) => {
    const updatedSections = [...learningLevelData];
    updatedSections[index].parameter[valueIndex] = value;
    setLearningLevelData(updatedSections);
  };

  const handleOperationClick = (index, operation) => {
    setLearningLevelData((prevData) => {
      const updatedSections = [...prevData];
      if (updatedSections[index].operator) {
        if (updatedSections[index].operator === operation) {
          return prevData;
        }
        updatedSections[index].operator = operation;
      } else {
        updatedSections[index].operator = operation;
        updatedSections.push({
          measure: "",
          condition: "",
          parameter: [],
          then: "",
          operator: "",
        });
      }

      return updatedSections;
    });
  };

  const handleDeleteSection = (index, sectionType) => {
    if (sectionType === "learningLevel") {
      setLearningLevelData((prevData) =>
        prevData.filter((_, i) => i !== index)
      );
    } else if (sectionType === "learningPace") {
      setLearningPaceData((prevData) => prevData.filter((_, i) => i !== index));
    } else if (sectionType === "performance") {
      setPerformanceData((prevData) => prevData.filter((_, i) => i !== index));
    }
  };

  const handleEditClick = (sectionType) => {
    if (sectionType === "learningLevel") {
      const updatedSections = learningLevelData.map((section) => ({
        ...section,
        isDisabled: false,
      }));
      setLearningLevelData(updatedSections);
    } else if (sectionType === "learningPace") {
      const updatedSections = learningPaceData.map((section) => ({
        ...section,
        isDisabled: false,
      }));
      setLearningPaceData(updatedSections);
    } else if (sectionType === "performance") {
      const updatedSections = performanceData.map((section) => ({
        ...section,
        isDisabled: false,
      }));
      setPerformanceData(updatedSections);
    }
  };

  //learning Pace functions

  const handlePaceInputChange = (index, field, value, name = "") => {
    const updatedSections = [...learningPaceData];
    if (field === "course") {
      updatedSections[index].course_id = value;
      updatedSections[index].course_name = name;
      topicGet(value, index);
    } else if (field === "module") {
      updatedSections[index].module_id = value;
      updatedSections[index].module = name;
    } else {
      updatedSections[index][field] = value;
    }

    if (field === "condition") {
      updatedSections[index].parameter =
        value === "Between"
          ? [updatedSections[index].parameter[0] || "", ""]
          : [updatedSections[index].parameter[0] || ""];
    }

    setLearningPaceData(updatedSections);
  };
  const handlePaceValuesChange = (index, valueIndex, value) => {
    const updatedSections = [...learningPaceData];
    updatedSections[index].parameter[valueIndex] = value;
    setLearningPaceData(updatedSections);
  };

  const handlePaceOperationClick = (index, operation) => {
    setLearningPaceData((prevData) => {
      const updatedSections = [...prevData];
      if (updatedSections[index].operator) {
        if (updatedSections[index].operator === operation) {
          return prevData;
        }
        updatedSections[index].operator = operation;
      } else {
        updatedSections[index].operator = operation;
        updatedSections.push({
          course_name: "",
          course_id: "",
          module: "",
          module_id: "",
          measure: "",
          condition: "",
          parameter: [],
          then: "",
          operator: "",
          isDisabled: false,
          modules: [],
        });
      }

      return updatedSections;
    });
  };

  //Performance functions

  const handlePerformanceInputChange = (index, field, value) => {
    const updatedSections = [...performanceData];
    updatedSections[index][field] = value;
    if (field === "condition") {
      if (value === "Between") {
        updatedSections[index].parameter = [
          updatedSections[index].parameter[0] || "",
          "",
        ];
      } else {
        updatedSections[index].parameter = [
          updatedSections[index].parameter[0] || "",
        ];
      }
    }

    setPerformanceData(updatedSections);
  };

  const handlePerformanceValuesChange = (index, valueIndex, value) => {
    const updatedSections = [...performanceData];
    updatedSections[index].parameter[valueIndex] = value;
    setPerformanceData(updatedSections);
  };

  const handlePerformanceOperationClick = (index, operation) => {
    setPerformanceData((prevData) => {
      const updatedSections = [...prevData];
      if (updatedSections[index].operator) {
        if (updatedSections[index].operator === operation) {
          return prevData;
        }
        updatedSections[index].operator = operation;
      } else {
        updatedSections[index].operator = operation;
        updatedSections.push({
          measure: "",
          condition: "",
          parameter: [],
          then: "",
          operator: "",
          isDisabled: false,
        });
      }

      return updatedSections;
    });
  };

  //badge create function
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCreateFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = async (e) => {
    const { name, files } = e.target;
    const file = files[0];

    if (file) {
      const formData = new FormData();
      formData.append("contents", file);

      try {
        const response = await fetch(`${baseUrl}/url`, {
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          const responseData = await response.json();
          console.log(responseData, "responseData");

          const fileUrl = responseData.data[0].url;
          setCreateFields((prev) => ({
            ...prev,
            [name]: fileUrl,
          }));
        } else {
          console.error("Failed to upload file", response);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const handleEditFileChange = async (e) => {
    const { name, files } = e.target;
    const file = files[0];

    if (file) {
      const formData = new FormData();
      formData.append("contents", file);

      try {
        const response = await fetch(`${baseUrl}/url`, {
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          const responseData = await response.json();
          console.log(responseData, "responseData");

          const fileUrl = responseData.data[0].url;
          setEditFields((prev) => ({
            ...prev,
            [name]: fileUrl,
          }));
        } else {
          console.error("Failed to upload file", response);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  //Badge topic configuration

  const handleTopicInputChange = (index, field, value,name = "") => {
    const updatedSections = [...topicData];
    if(field==="then"){
      updatedSections[index].then = value;
      updatedSections[index].badge_name = name;
    }
    else{
      updatedSections[index][field] = value;
    }
    if (field === "condition") {
      if (value === "Between") {
        updatedSections[index].parameter = [
          updatedSections[index].parameter[0] || "",
          "",
        ];
      } else {
        updatedSections[index].parameter = [
          updatedSections[index].parameter[0] || "",
        ];
      }
    }

    setTopicData(updatedSections);
  };

  const handleTopicValuesChange = (index, valueIndex, value) => {
    const updatedSections = [...topicData];
    updatedSections[index].parameter[valueIndex] = value;
    setTopicData(updatedSections);
  };

  const handleTopicOperationClick = (index, operation) => {
    setTopicData((prevData) => {
      const updatedSections = [...prevData];
      if (updatedSections[index].operator) {
        if (updatedSections[index].operator === operation) {
          return prevData;
        }
        updatedSections[index].operator = operation;
      } else {
        updatedSections[index].operator = operation;
        updatedSections.push({
          measure: "",
          condition: "",
          parameter: [],
          then: "",
          badge_name:"",
          operator: "",
          isDisabled: false,
        });
      }

      return updatedSections;
    });
  };

  const handleDailyInputChange = (index, field, value,name = "") => {
    const updatedSections = [...dailyData];
    if(field==="then"){
      updatedSections[index].then = value;
      updatedSections[index].badge_name = name;
    }
    else{
      updatedSections[index][field] = value;
    }
    if (field === "condition") {
      if (value === "Between") {
        updatedSections[index].parameter = [
          updatedSections[index].parameter[0] || "",
          "",
        ];
      } else {
        updatedSections[index].parameter = [
          updatedSections[index].parameter[0] || "",
        ];
      }
    }

    setDailyData(updatedSections);
  };

  const handleDailyValuesChange = (index, valueIndex, value) => {
    const updatedSections = [...dailyData];
    updatedSections[index].parameter[valueIndex] = value;
    setDailyData(updatedSections);
  };

  const handleDailyOperationClick = (index, operation) => {
    setDailyData((prevData) => {
      const updatedSections = [...prevData];
      if (updatedSections[index].operator) {
        if (updatedSections[index].operator === operation) {
          return prevData;
        }
        updatedSections[index].operator = operation;
      } else {
        updatedSections[index].operator = operation;
        updatedSections.push({
          measure: "",
          condition: "",
          parameter: [],
          then: "",
          badge_name:"",
          operator: "",
          isDisabled: false,
        });
      }

      return updatedSections;
    });
  };

  const handleWeaklyInputChange = (index, field, value,name = "") => {
    const updatedSections = [...weaklyData];
    if(field==="then"){
      updatedSections[index].then = value;
      updatedSections[index].badge_name = name;
    }
    else{
      updatedSections[index][field] = value;
    }
    if (field === "condition") {
      if (value === "Between") {
        updatedSections[index].parameter = [
          updatedSections[index].parameter[0] || "",
          "",
        ];
      } else {
        updatedSections[index].parameter = [
          updatedSections[index].parameter[0] || "",
        ];
      }
    }

    setWeaklyData(updatedSections);
  };

  const handleWeaklyValuesChange = (index, valueIndex, value) => {
    const updatedSections = [...weaklyData];
    updatedSections[index].parameter[valueIndex] = value;
    setWeaklyData(updatedSections);
  };

  const handleWeaklyOperationClick = (index, operation) => {
    setWeaklyData((prevData) => {
      const updatedSections = [...prevData];
      if (updatedSections[index].operator) {
        if (updatedSections[index].operator === operation) {
          return prevData;
        }
        updatedSections[index].operator = operation;
      } else {
        updatedSections[index].operator = operation;
        updatedSections.push({
          measure: "",
          condition: "",
          parameter: [],
          then: "",
          badge_name:"",
          operator: "",
          isDisabled: false,
        });
      }

      return updatedSections;
    });
  };

  //badge configuration creation

  const handleBadgeCreateSession = () => {
    const cleanedTopicData = topicData.map(({ isDisabled, ...rest }) => rest);
    const cleanedDailyData = dailyData.map(({ isDisabled, ...rest }) => rest);
    const cleanedWeeklyData = weaklyData.map(({ isDisabled, ...rest }) => rest);

    const data = {
      gamification_id: ruleData[1]?.gamification_id,
      sections: [
        {
          SectionName: "Topics_Modules",
          rules: cleanedTopicData,
          section_id: topicSessionID || "",
          trigger: "On_Topic_and_Module_Completions",
        },
        {
          SectionName: "Daily_Drill",
          rules: cleanedDailyData,
          section_id: dailySessionID || "",
          trigger: "On_Daily_Drill",
        },
        {
          SectionName: "Weakly_Workout",
          rules: cleanedWeeklyData,
          section_id: weaklySessionID || "",
          trigger: "On_Weakly_Workout",
        },
      ],
    };

    createSession(data);
  };

  //badge topic delete
  const handleBadgeDeleteSection = (index, sectionType) => {
    if (sectionType === "topic_module") {
      setTopicData((prevData) => prevData.filter((_, i) => i !== index));
    } else if (sectionType === "daily_drill") {
      setDailyData((prevData) => prevData.filter((_, i) => i !== index));
    } else if (sectionType === "weakly_workout") {
      setWeaklyData((prevData) => prevData.filter((_, i) => i !== index));
    }
  };

  const handleBadgeEditClick = (sectionType) => {
    if (sectionType === "topic_module") {
      const updatedSections = topicData.map((section) => ({
        ...section,
        isDisabled: false,
      }));
      setTopicData(updatedSections);
    } else if (sectionType === "daily_drill") {
      const updatedSections = dailyData.map((section) => ({
        ...section,
        isDisabled: false,
      }));
      setDailyData(updatedSections);
    } else if (sectionType === "weakly_workout") {
      const updatedSections = weaklyData.map((section) => ({
        ...section,
        isDisabled: false,
      }));
      setWeaklyData(updatedSections);
    }
  };

  async function getRules() {
    await fetch(`${baseUrl}/get/gamification-rule/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setRuleData(data.data);
        } else {
          setRuleData([]);
        }
      });
  }

  const handleCreateSession = () => {
    const cleanedLearningLevelData = learningLevelData.map(
      ({ isDisabled, ...rest }) => rest
    );
    const cleanedLearningPaceData = learningPaceData.map(
      ({ isDisabled, modules, ...rest }) => rest
    );
    const cleanedPerformanceData = performanceData.map(
      ({ isDisabled, ...rest }) => rest
    );

    const data = {
      gamification_id: ruleData[0]?.gamification_id,
      sections: [
        {
          SectionName: "Learning_Level",
          rules: cleanedLearningLevelData,
          section_id: learningLevelSessionID || "",
          trigger: "On_Topic_Completions",
        },
        {
          SectionName: "Learning_Pace",
          rules: cleanedLearningPaceData,
          section_id: learningPaceSessionID || "",
          trigger: "Upon_Topic_Assessment_Completion",
        },
        {
          SectionName: "Performance",
          rules: cleanedPerformanceData,
          section_id: performanceSessionID || "",
          trigger: "Upon Point calculation",
        },
      ],
    };

    createSession(data);
  };

  async function getSession(id) {
    await fetch(`${baseUrl}/get/gamification-rule/individual`, {
      method: "POST",
      body: JSON.stringify({
        gamification_id: id,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true && data.data[0]?.sections?.length) {
          const learningLevelSection = data.data[0].sections.find(
            (section) => section.SectionName === "Learning_Level"
          );
          if (learningLevelSection) {
            setLearningLevelSessionID(learningLevelSection.section_id);
            const levelRules = learningLevelSection.rules.map((rule) => ({
              measure: rule.measure,
              condition: rule.condition,
              parameter: rule.parameter,
              then: rule.then,
              operator: rule.operator,
              isDisabled: true,
            }));
            setLearningLevelData(levelRules);
          }
          const learningPaceSection = data.data[0].sections.find(
            (section) => section.SectionName === "Learning_Pace"
          );
          if (learningPaceSection) {
            setLearningPaceSessionID(learningPaceSection.section_id);
            const paceRules = learningPaceSection.rules.map((rule) => ({
              course_name: rule.course_name || "",
              course_id: rule.course_id || "",
              module: rule.module || "",
              module_id: rule.module_id || "",
              measure: rule.measure,
              condition: rule.condition,
              parameter: rule.parameter,
              then: rule.then,
              operator: rule.operator,
              isDisabled: true,
              modules: [],
            }));
            setLearningPaceData(paceRules);
            paceRules.forEach((rule, index) => {
              if (rule.course_id) {
                topicGet(rule.course_id, index);
              }
            });
          }
          const performanceSection = data.data[0].sections.find(
            (section) => section.SectionName === "Performance"
          );
          if (performanceSection) {
            setPerformanceSessionID(performanceSection.section_id);
            const performanceRules = performanceSection.rules.map((rule) => ({
              measure: rule.measure,
              condition: rule.condition,
              parameter: rule.parameter,
              then: rule.then,
              operator: rule.operator,
              isDisabled: true,
            }));
            setPerformanceData(performanceRules);
          }
          const topicSection = data.data[0].sections.find(
            (section) => section.SectionName === "Topics_Modules"
          );
          if (topicSection) {
            setTopicSessionID(topicSection.section_id);
            const topicRules = topicSection.rules.map((rule) => ({
              measure: rule.measure,
              condition: rule.condition,
              parameter: rule.parameter,
              then: rule.then,
              badge_name:rule.badge_name,
              operator: rule.operator,
              isDisabled: true,
            }));
            setTopicData(topicRules);
          }
          const dailySection = data.data[0].sections.find(
            (section) => section.SectionName === "Daily_Drill"
          );
          if (dailySection) {
            setDailySessionID(dailySection.section_id);
            const dailyRules = dailySection.rules.map((rule) => ({
              measure: rule.measure,
              condition: rule.condition,
              parameter: rule.parameter,
              then: rule.then,
              badge_name:rule.badge_name,
              operator: rule.operator,
              isDisabled: true,
            }));
            setDailyData(dailyRules);
          }
          const weaklySection = data.data[0].sections.find(
            (section) => section.SectionName === "Weakly_Workout"
          );
          if (weaklySection) {
            setWeaklySessionID(weaklySection.section_id);
            const weaklyRules = weaklySection.rules.map((rule) => ({
              measure: rule.measure,
              condition: rule.condition,
              parameter: rule.parameter,
              then: rule.then,
              badge_name:rule.badge_name,
              operator: rule.operator,
              isDisabled: true,
            }));
            setWeaklyData(weaklyRules);
          }
        } else {
          setLearningLevelData([
            {
              measure: "",
              condition: "",
              parameter: [],
              then: "",
              operator: "",
              isDisabled: false,
            },
          ]);
          setLearningPaceData([
            {
              course_name: "",
              course_id: "",
              module: "",
              module_id: "",
              measure: "",
              condition: "",
              parameter: [],
              then: "",
              operator: "",
              isDisabled: false,
              modules: [],
            },
          ]);
          setPerformanceData([
            {
              measure: "",
              condition: "",
              parameter: [],
              then: "",
              operator: "",
              isDisabled: false,
            },
          ]);
          setTopicData([
            {
              measure: "",
              condition: "",
              parameter: [],
              then: "",
              badge_name:"",
              operator: "",
              isDisabled: false,
            },
          ]);
          setDailyData([
            {
              measure: "",
              condition: "",
              parameter: [],
              then: "",
              badge_name:"",
              operator: "",
              isDisabled: false,
            },
          ]);
          setWeaklyData([
            {
              measure: "",
              condition: "",
              parameter: [],
              then: "",
              badge_name:"",
              operator: "",
              isDisabled: false,
            },
          ]);
        }
      });
  }

  async function createSession(data) {
    await fetch(`${baseUrl}/create/sections`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          if (selectedTab === 0 && ruleData.length > 0) {
            getSession(ruleData[0].gamification_id);
          } else if (selectedTab === 2 && ruleData.length > 0) {
            getSession(ruleData[1].gamification_id);
          }
        } else {
        }
      });
  }

  async function createBadge() {
    await fetch(`${baseUrl}/create/badge`, {
      method: "POST",
      body: JSON.stringify(createFields),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setCreateFields({
            name: "",
            description: "",
            type: "",
            default_img: "",
            active_img: "",
          });
          setCreatePopup(false);
          getBadges();
        } else {
        }
      });
  }

  async function updateBadge() {
    await fetch(`${baseUrl}/edit/badge`, {
      method: "POST",
      body: JSON.stringify(editFields),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
         setEditPopup(false)
         setEditFields({})
         getBadges();
        } else {
        }
      });
  }

  async function getBadges() {
    await fetch(`${baseUrl}/get/badge/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setBadgeList(data.data)
        } else {
        }
      });
  }

  async function categoryGet() {
    await fetch(`${baseUrl}/main/categories/get`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: selectedOrgId,
        type: "admin",
        // club_id: ClubId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setCategoryList(data.data);
          learningPaceData.forEach((rule, index) => {
            if (rule.course_id) {
              topicGet(rule.course_id, index);
            }
          });
        } else {
          setCategoryList([]);
        }
      });
  }

  async function topicGet(id, index) {
    await fetch(`${baseUrl}/topic/get`, {
      method: "POST",
      body: JSON.stringify({
        org_id: selectedOrgId,
        category_id: id,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setLearningPaceData((prevData) => {
            const updatedData = [...prevData];
            updatedData[index].modules = data.data;
            return updatedData;
          });
        }
      });
  }

  async function OrgGet() {
    const userId = localStorage.getItem("adminUserId");
    await fetch(`${baseUrl}/org/get/admin/per_users`, {
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "orgData");
        setOrgList(data.data);
      });
  }

  useEffect(() => {
    getRules();
  }, [key]);

  useEffect(() => {
    if (selectedOrgId) {
      categoryGet();
    }
  }, [selectedOrgId]);

  useEffect(() => {
    if (selectedTab === 0 && ruleData.length > 0) {
      getSession(ruleData[0].gamification_id);
    }
    else if(selectedTab===1){
      getBadges();
    } else if (selectedTab === 2 && ruleData.length > 0) {
      getSession(ruleData[1].gamification_id);
      getBadges();
    }
  }, [selectedTab, ruleData]);

  useEffect(() => {
    if (
      (adminStatus === false || adminStatus === "false") &&
      userRole !== "Partner"
    ) {
      const orgid = profile?.org_id;
      if (orgid !== undefined && orgid !== "") {
        // ClubGet(orgid);
        setSelectedOrgId(orgid);
        categoryGet(orgid);
      }
    } else {
      OrgGet();
    }
  }, [adminStatus, profile]);

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.headerText}>
          <p>Gamification Configurations</p>
        </div>
        <div className={style.headerButton}>
          {(adminStatus === false || adminStatus === "false") &&
          userRole !== "Partner" ? (
            ""
          ) : (
            <select
              value={selectedOrgId}
              onChange={(e) => {
                const selectedOrgID = e.target.value;
                setSelectedOrgId(selectedOrgID);
                categoryGet(selectedOrgID);
              }}
              style={{ width: "100%" }}
            >
              <option value="" disabled>
                Select Organization
              </option>
              {orgList && orgList.length > 0 ? (
                orgList.map((org) => (
                  <option key={org.org_id} value={org.org_id}>
                    {org.org_name}
                  </option>
                ))
              ) : (
                <option disabled>No organizations available</option>
              )}
            </select>
          )}
          <button
            disabled={selectedTab === 1}
            onClick={() => {
              if (selectedTab === 0) {
                handleCreateSession();
              } else if (selectedTab === 2) {
                handleBadgeCreateSession();
              }
            }}
          >
            Save
          </button>
        </div>
      </div>
      {showContent?
      <div className={style.sections}>
        <div className={style.tabHeader}>
          <div
            onClick={() => setSelectedTab(0)}
            className={`${style.tab} ${
              selectedTab === 0 ? style.activeTab : ""
            }`}
          >
            <p>Rewards point</p>
          </div>
          <div
            onClick={() => setSelectedTab(1)}
            className={`${style.tab} ${
              selectedTab === 1 ? style.activeTab : ""
            }`}
          >
            <p>Badge List</p>
          </div>
          <div
            onClick={() => setSelectedTab(2)}
            className={`${style.tab} ${
              selectedTab === 2 ? style.activeTab : ""
            }`}
          >
            <p>Badge configuration</p>
          </div>
        </div>
        <div className={style.tabSection}>
          {selectedTab === 0 && (
            <div className={style.rewardSection}>
              <div className={style.typeContainer}>
                <div className={style.typeHeader}>
                  <p>Learning Level</p>
                  {learningLevelSessionID ? (
                    <MdEdit onClick={() => handleEditClick("learningLevel")} />
                  ) : (
                    ""
                  )}
                </div>
                {learningLevelData.map((section, index) => (
                  <div key={index} className={style.learningSection}>
                    <div className={style.inputSection}>
                      <div className={style.inputContainer}>
                        <label>Measure</label>
                        <select
                          value={section.measure}
                          onChange={(e) =>
                            handleInputChange(index, "measure", e.target.value)
                          }
                          disabled={section.isDisabled}
                        >
                          <option value="">Select Measure</option>
                          <option value="Average Score">Average Score</option>
                        </select>
                      </div>

                      <div className={style.inputContainer}>
                        <label>Condition</label>
                        <select
                          value={section.condition}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "condition",
                              e.target.value
                            )
                          }
                          disabled={section.isDisabled}
                        >
                          <option value="">Select Condition</option>
                          <option value="Above">Above</option>
                          <option value="Between">Between</option>
                          <option value="Below">Below</option>
                        </select>
                      </div>

                      <div className={style.inputContainer}>
                        <label>Parameters</label>
                        <select
                          value={section.parameter[0] || ""}
                          onChange={(e) =>
                            handleValuesChange(index, 0, e.target.value)
                          }
                          disabled={section.isDisabled}
                        >
                          <option value="">Select Value</option>
                          {Array.from({ length: 100 }, (_, i) => (
                            <option key={i + 1} value={i + 1}>
                              {i + 1}
                            </option>
                          ))}
                        </select>
                      </div>

                      {section.condition === "Between" && (
                        <div className={style.inputContainer}>
                          <label>Parameters</label>
                          <select
                            value={section.parameter[1] || ""}
                            onChange={(e) =>
                              handleValuesChange(index, 1, e.target.value)
                            }
                            disabled={section.isDisabled}
                          >
                            <option value="">Select Second Value</option>
                            {Array.from({ length: 100 }, (_, i) => (
                              <option key={i + 1} value={i + 1}>
                                {i + 1}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}

                      <div className={style.inputContainer}>
                        <label>Reward</label>
                        <input
                          type="text"
                          value={section.then}
                          onChange={(e) =>
                            handleInputChange(index, "then", e.target.value)
                          }
                          disabled={section.isDisabled}
                        />
                      </div>

                      <div className={style.buttonContainer}>
                        {section.operator === "" && !section.isDisabled && (
                          <button
                            onClick={() => handleOperationClick(index, "AND")}
                          >
                            AND
                          </button>
                        )}
                        {section.operator === "" && !section.isDisabled && (
                          <button
                            onClick={() => handleOperationClick(index, "OR")}
                          >
                            OR
                          </button>
                        )}
                        {section.operator &&
                          learningLevelData.length > 1 &&
                          !section.isDisabled && (
                            <button
                              style={{ background: "transparent" }}
                              onClick={() =>
                                handleDeleteSection(index, "learningLevel")
                              }
                            >
                              <IoCloseOutline size={20} />
                            </button>
                          )}
                      </div>
                    </div>

                    <div className={style.operator}>
                      {section.operator && (
                        <p className={style.operationText}>
                          {section.operator}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              <div className={style.typeContainer}>
                <div className={style.typeHeader}>
                  <p>Learning Pace</p>
                  {learningPaceSessionID ? (
                    <MdEdit onClick={() => handleEditClick("learningPace")} />
                  ) : (
                    ""
                  )}
                </div>
                {learningPaceData.map((section, index) => (
                  <div className={style.learningSection}>
                    <div key={index} className={style.inputSection}>
                      <div className={style.inputContainer}>
                        <label>Course</label>
                        <select
                          value={section.course_id}
                          disabled={section.isDisabled}
                          onChange={(e) => {
                            const selectedOption =
                              e.target.options[e.target.selectedIndex];
                            handlePaceInputChange(
                              index,
                              "course",
                              e.target.value,
                              selectedOption.text
                            );
                          }}
                        >
                          <option value="">Select Course</option>
                          {categoryList &&
                            categoryList.length > 0 &&
                            categoryList.map((cat) => (
                              <option
                                key={cat.category_id}
                                value={cat.category_id}
                              >
                                {cat.Name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className={style.inputContainer}>
                        <label>Module</label>
                        <select
                          value={section.module_id}
                          disabled={section.isDisabled}
                          onChange={(e) => {
                            const selectedOption =
                              e.target.options[e.target.selectedIndex];
                            handlePaceInputChange(
                              index,
                              "module",
                              e.target.value,
                              selectedOption.text
                            );
                          }}
                        >
                          <option value="">Select Module</option>
                          {section.modules &&
                            section.modules.length > 0 &&
                            section.modules.map((mod) => (
                              <option key={mod.module_id} value={mod.module_id}>
                                {mod.module_name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className={style.inputContainer}>
                        <label>Measure</label>
                        <select
                          disabled={section.isDisabled}
                          value={section.measure}
                          onChange={(e) =>
                            handlePaceInputChange(
                              index,
                              "measure",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Measure</option>
                          <option value="Actual Duration">
                            Actual Duration
                          </option>
                        </select>
                      </div>

                      <div className={style.inputContainer}>
                        <label>Condition</label>
                        <select
                          disabled={section.isDisabled}
                          value={section.condition}
                          onChange={(e) =>
                            handlePaceInputChange(
                              index,
                              "condition",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Condition</option>
                          <option value="Above">Above</option>
                          <option value="Between">Between</option>
                          <option value="Below">Below</option>
                        </select>
                      </div>

                      <div className={style.inputContainer}>
                        <label>Parameters</label>
                        <select
                          disabled={section.isDisabled}
                          value={section.parameter[0] || ""}
                          onChange={(e) =>
                            handlePaceValuesChange(index, 0, e.target.value)
                          }
                        >
                          <option value="">Select Value</option>
                          {Array.from({ length: 100 }, (_, i) => (
                            <option key={i + 1} value={i + 1}>
                              {i + 1}weeks
                            </option>
                          ))}
                        </select>
                      </div>

                      {section.condition === "Between" && (
                        <div className={style.inputContainer}>
                          <label>Parameters</label>
                          <select
                            disabled={section.isDisabled}
                            value={section.parameter[1] || ""}
                            onChange={(e) =>
                              handlePaceValuesChange(index, 1, e.target.value)
                            }
                          >
                            <option value="">Select Value</option>
                            {Array.from({ length: 100 }, (_, i) => (
                              <option key={i + 1} value={i + 1}>
                                {i + 1}weeks
                              </option>
                            ))}
                          </select>
                        </div>
                      )}

                      <div className={style.inputContainer}>
                        <label>Reward</label>
                        <input
                          disabled={section.isDisabled}
                          type="text"
                          value={section.then}
                          onChange={(e) =>
                            handlePaceInputChange(index, "then", e.target.value)
                          }
                        />
                      </div>

                      <div className={style.buttonContainer}>
                        {section.operator === "" && !section.isDisabled && (
                          <button
                            onClick={() =>
                              handlePaceOperationClick(index, "AND")
                            }
                          >
                            AND
                          </button>
                        )}
                        {section.operator === "" && !section.isDisabled && (
                          <button
                            onClick={() =>
                              handlePaceOperationClick(index, "OR")
                            }
                          >
                            OR
                          </button>
                        )}
                        {section.operator &&
                          learningLevelData.length > 1 &&
                          !section.isDisabled && (
                            <button
                              style={{ background: "transparent" }}
                              onClick={() =>
                                handleDeleteSection(index, "learningPace")
                              }
                            >
                              <IoCloseOutline size={20} />
                            </button>
                          )}
                      </div>
                    </div>
                    <div className={style.operator}>
                      {section.operator && (
                        <p className={style.operationText}>
                          {section.operator}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className={style.typeContainer}>
                <div className={style.typeHeader}>
                  <p>Performance</p>
                  {performanceSessionID ? (
                    <MdEdit onClick={() => handleEditClick("performance")} />
                  ) : (
                    ""
                  )}
                </div>
                {performanceData.map((section, index) => (
                  <div className={style.learningSection}>
                    <div key={index} className={style.inputSection}>
                      <div className={style.inputContainer}>
                        <label>Measure</label>
                        <select
                          disabled={section.isDisabled}
                          value={section.measure}
                          onChange={(e) =>
                            handlePerformanceInputChange(
                              index,
                              "measure",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Measure</option>
                          <option value="Reward Points">Reward Points</option>
                        </select>
                      </div>

                      <div className={style.inputContainer}>
                        <label>Condition</label>
                        <select
                          disabled={section.isDisabled}
                          value={section.condition}
                          onChange={(e) =>
                            handlePerformanceInputChange(
                              index,
                              "condition",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Condition</option>
                          <option value="Above">Above</option>
                          <option value="Between">Between</option>
                          <option value="Below">Below</option>
                        </select>
                      </div>

                      <div className={style.inputContainer}>
                        <label>Parameters</label>
                        <input
                          disabled={section.isDisabled}
                          type="number"
                          value={section.parameter[0] || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value >= 0 || value === "") {
                              handlePerformanceValuesChange(index, 0, value);
                            }
                          }}
                          placeholder="Enter value"
                        />
                      </div>

                      {section.condition === "Between" && (
                        <div className={style.inputContainer}>
                          <label>Parameters</label>
                          <input
                            disabled={section.isDisabled}
                            type="number"
                            value={section.parameter[1] || ""}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value >= 0 || value === "") {
                                handlePerformanceValuesChange(index, 1, value);
                              }
                            }}
                            placeholder="Enter value"
                          />
                        </div>
                      )}

                      <div className={style.inputContainer}>
                        <label>Reward</label>
                        <select
                          disabled={section.isDisabled}
                          value={section.then}
                          onChange={(e) =>
                            handlePerformanceInputChange(
                              index,
                              "then",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Reward</option>
                          <option value="Bronze">Bronze</option>
                          <option value="Silver">Silver</option>
                          <option value="Gold">Gold</option>
                        </select>
                      </div>

                      <div className={style.buttonContainer}>
                        {section.operator === "" && !section.isDisabled && (
                          <button
                            onClick={() =>
                              handlePerformanceOperationClick(index, "AND")
                            }
                          >
                            AND
                          </button>
                        )}
                        {section.operator === "" && !section.isDisabled && (
                          <button
                            onClick={() =>
                              handlePerformanceOperationClick(index, "OR")
                            }
                          >
                            OR
                          </button>
                        )}
                        {section.operator &&
                          learningLevelData.length > 1 &&
                          !section.isDisabled && (
                            <button
                              style={{ background: "transparent" }}
                              onClick={() =>
                                handleDeleteSection(index, "performance")
                              }
                            >
                              <IoCloseOutline size={20} />
                            </button>
                          )}
                      </div>
                    </div>
                    <div className={style.operator}>
                      {section.operator && (
                        <p className={style.operationText}>
                          {section.operator}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {selectedTab === 1 && (
            <div className={style.badgeContainer}>
              <div className={style.badgeHeader}>
                <p>Badge List</p>
                <button onClick={() => setCreatePopup(true)}>Create</button>
              </div>
              <div className={style.badgeTable}>
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Default Image</th>
                      <th>Active Image</th>
                      <th>Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {badgeList&&badgeList.length>0?badgeList.map((badge)=>(
                      <tr key={badge.badge_id}>
                      <td>{badge.name}</td>
                      <td>{badge.description}</td>
                      <td style={{textAlign:"center"}}><img src={badge.default_img} width="70px" alt={badge.name}/></td>
                      <td style={{textAlign:"center"}}><img src={badge.active_img} width="70px" alt={badge.name}/></td>
                      <td>{badge.type}</td>
                      <td>
                        <MdEdit
                          className={style.editIcon}
                          onClick={() => {
                            setEditFields(badge);
                            setEditPopup(true);
                          }}
                        />
                        <RiDeleteBinLine
                          className={style.deleteIcon}
                          onClick={() => setDeletePopup(true)}
                        />
                      </td>
                    </tr>
                    )):""}
                    
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {selectedTab === 2 && (
            <div className={style.rewardSection}>
              <div className={style.typeContainer}>
                <div className={style.typeHeader}>
                  <p>Topics & Modules</p>
                  {topicSessionID ? (
                    <MdEdit
                      onClick={() => handleBadgeEditClick("topic_module")}
                    />
                  ) : (
                    ""
                  )}
                </div>
                {topicData.map((section, index) => (
                  <div className={style.learningSection}>
                    <div key={index} className={style.inputSection}>
                      <div className={style.inputContainer}>
                        <label>Measure</label>
                        <select
                          disabled={section.isDisabled}
                          value={section.measure}
                          onChange={(e) =>
                            handleTopicInputChange(
                              index,
                              "measure",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Measure</option>
                          <option value="Topic">Avg .in topic</option>
                          <option value="Module">Avg .in Module</option>
                        </select>
                      </div>

                      <div className={style.inputContainer}>
                        <label>Condition</label>
                        <select
                          disabled={section.isDisabled}
                          value={section.condition}
                          onChange={(e) =>
                            handleTopicInputChange(
                              index,
                              "condition",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Condition</option>
                          <option value="Above">Above</option>
                          <option value="Between">Between</option>
                          <option value="Below">Below</option>
                          <option value="HigherThanPrevious">Higher Than Previous</option>
                          <option value="Completion">Completion</option>
                        </select>
                      </div>

                      <div className={style.inputContainer}>
                        <label>Parameters</label>
                        {section.condition==="HigherThanPrevious"?
                        <select
                        disabled={section.isDisabled}
                        value={section.parameter[0] || ""}
                        onChange={(e) =>
                          handleTopicValuesChange(index, 0, e.target.value)
                        }
                      >
                        <option value="">Select Value</option>
                        <option value="PreviousScore">Previous Score</option>
                        
                      </select>
                      :
                      section.condition==="Completion"?
                      <select
                      disabled={section.isDisabled}
                      value={section.parameter[0] || ""}
                      onChange={(e) =>
                        handleTopicValuesChange(index, 0, e.target.value)
                      }
                    >
                      <option value="">Select Value</option>
                      <option value="Actual Duration">Actual Duration</option>
                      <option value="Below Actual Duration">Below Actual Duration</option>
                      <option value="Above Actual Duration">Above Actual Duration</option>
                      
                    </select>:
                        <select
                          disabled={section.isDisabled}
                          value={section.parameter[0] || ""}
                          onChange={(e) =>
                            handleTopicValuesChange(index, 0, e.target.value)
                          }
                        >
                          <option value="">Select Value</option>
                          {Array.from({ length: 100 }, (_, i) => (
                            <option key={i + 1} value={i + 1}>
                              {i + 1}%
                            </option>
                          ))}
                        </select>
                        }
                      </div>

                      {section.condition === "Between" && (
                        <div className={style.inputContainer}>
                          <label>Parameters</label>
                          <select
                            disabled={section.isDisabled}
                            value={section.parameter[1] || ""}
                            onChange={(e) =>
                              handleTopicValuesChange(index, 1, e.target.value)
                            }
                          >
                            <option value="">Select Value</option>
                            {Array.from({ length: 100 }, (_, i) => (
                              <option key={i + 1} value={i + 1}>
                                {i + 1}%
                              </option>
                            ))}
                          </select>
                        </div>
                      )}

                      <div className={style.inputContainer}>
                        <label>Reward</label>
                        <select
                          disabled={section.isDisabled}
                          value={section.then}
                          onChange={(e) =>{
                            const selectedOption =
                            e.target.options[e.target.selectedIndex];
                            handleTopicInputChange(
                              index,
                              "then",
                              e.target.value,
                              selectedOption.text
                            )
                          }
                          }
                        >
                          <option value="">Select Reward</option>
                          {badgeList&&badgeList.length>0&&badgeList.filter((badge) => badge.type === "Topic and module").map((badge)=>(
                            <option key={badge.badge_id} value={badge.badge_id}>{badge.name}</option>
                          ))}
                        </select>
                      </div>

                      <div className={style.buttonContainer}>
                        {section.operator === "" && !section.isDisabled && (
                          <button
                            onClick={() =>
                              handleTopicOperationClick(index, "AND")
                            }
                          >
                            AND
                          </button>
                        )}
                        {section.operator === "" && !section.isDisabled && (
                          <button
                            onClick={() =>
                              handleTopicOperationClick(index, "OR")
                            }
                          >
                            OR
                          </button>
                        )}
                        {section.operator &&
                          topicData.length > 1 &&
                          !section.isDisabled && (
                            <button
                              style={{ background: "transparent" }}
                              onClick={() =>
                                handleBadgeDeleteSection(index, "topic_module")
                              }
                            >
                              <IoCloseOutline size={20} />
                            </button>
                          )}
                      </div>
                    </div>
                    <div className={style.operator}>
                      {section.operator && (
                        <p className={style.operationText}>
                          {section.operator}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              <div className={style.typeContainer}>
                <div className={style.typeHeader}>
                  <p>Daily Drill</p>
                  {dailySessionID ? (
                    <MdEdit
                      onClick={() => handleBadgeEditClick("daily_drill")}
                    />
                  ) : (
                    ""
                  )}
                </div>
                {dailyData.map((section, index) => (
                  <div className={style.learningSection}>
                    <div key={index} className={style.inputSection}>
                      <div className={style.inputContainer}>
                        <label>Measure</label>
                        <select
                          disabled={section.isDisabled}
                          value={section.measure}
                          onChange={(e) =>
                            handleDailyInputChange(
                              index,
                              "measure",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Measure</option>
                          <option value="Avg .in Daily Drill">Avg .in Daily Drill</option>
                          <option value="Completion">Completion</option>
                        </select>
                      </div>

                      <div className={style.inputContainer}>
                        <label>Condition</label>
                        <select
                          disabled={section.isDisabled}
                          value={section.condition}
                          onChange={(e) =>
                            handleDailyInputChange(
                              index,
                              "condition",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Condition</option>
                          <option value="Above">Above</option>
                          <option value="Between">Between</option>
                          <option value="Below">Below</option>
                          <option value="Equal to">Equal to</option>
                        </select>
                      </div>

                      <div className={style.inputContainer}>
                        <label>Parameters</label>
                        <select
                          disabled={section.isDisabled}
                          value={section.parameter[0] || ""}
                          onChange={(e) =>
                            handleDailyValuesChange(index, 0, e.target.value)
                          }
                        >
                          <option value="">Select Value</option>
                          {Array.from({ length: 100 }, (_, i) => (
                            <option key={i + 1} value={i + 1}>
                              {i + 1}{section.measure==="Completion"?"days":"%"}
                            </option>
                          ))}
                        </select>
                      </div>

                      {section.condition === "Between" && (
                        <div className={style.inputContainer}>
                          <label>Parameters</label>
                          <select
                            disabled={section.isDisabled}
                            value={section.parameter[1] || ""}
                            onChange={(e) =>
                              handleDailyValuesChange(index, 1, e.target.value)
                            }
                          >
                            <option value="">Select Value</option>
                            {Array.from({ length: 100 }, (_, i) => (
                              <option key={i + 1} value={i + 1}>
                                {i + 1}{section.measure==="Completion"?"days":"%"}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}

                      <div className={style.inputContainer}>
                        <label>Reward</label>
                        <select
                          disabled={section.isDisabled}
                          value={section.then}
                          onChange={(e) =>{
                            const selectedOption =
                            e.target.options[e.target.selectedIndex];
                            handleDailyInputChange(
                              index,
                              "then",
                              e.target.value,
                              selectedOption.text
                            )
                          }
                          }
                        >
                          <option value="">Select Reward</option>
                          {badgeList&&badgeList.length>0&&badgeList.filter((badge) => badge.type === "Daily Drill").map((badge)=>(
                            <option key={badge.badge_id} value={badge.badge_id}>{badge.name}</option>
                          ))}
                        </select>
                      </div>

                      <div className={style.buttonContainer}>
                        {section.operator === "" && !section.isDisabled && (
                          <button
                            onClick={() =>
                              handleDailyOperationClick(index, "AND")
                            }
                          >
                            AND
                          </button>
                        )}
                        {section.operator === "" && !section.isDisabled && (
                          <button
                            onClick={() =>
                              handleDailyOperationClick(index, "OR")
                            }
                          >
                            OR
                          </button>
                        )}
                        {section.operator &&
                          dailyData.length > 1 &&
                          !section.isDisabled && (
                            <button
                              style={{ background: "transparent" }}
                              onClick={() =>
                                handleBadgeDeleteSection(index, "daily_drill")
                              }
                            >
                              <IoCloseOutline size={20} />
                            </button>
                          )}
                      </div>
                    </div>
                    <div className={style.operator}>
                      {section.operator && (
                        <p className={style.operationText}>
                          {section.operator}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              <div className={style.typeContainer}>
                <div className={style.typeHeader}>
                  <p>Weakly Workout</p>
                  {weaklySessionID ? (
                    <MdEdit
                      onClick={() => handleBadgeEditClick("weakly_workout")}
                    />
                  ) : (
                    ""
                  )}
                </div>
                {weaklyData.map((section, index) => (
                  <div className={style.learningSection}>
                    <div key={index} className={style.inputSection}>
                      <div className={style.inputContainer}>
                        <label>Measure</label>
                        <select
                          disabled={section.isDisabled}
                          value={section.measure}
                          onChange={(e) =>
                            handleWeaklyInputChange(
                              index,
                              "measure",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Measure</option>
                          <option value="Avg .in weakly workout">Avg .in Weakly workout</option>
                          <option value="Completion">Completion</option>
                        </select>
                      </div>

                      <div className={style.inputContainer}>
                        <label>Condition</label>
                        <select
                          disabled={section.isDisabled}
                          value={section.condition}
                          onChange={(e) =>
                            handleWeaklyInputChange(
                              index,
                              "condition",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Condition</option>
                          <option value="Above">Above</option>
                          <option value="Between">Between</option>
                          <option value="Below">Below</option>
                          <option value="Equal to">Equal to</option>
                        </select>
                      </div>

                      <div className={style.inputContainer}>
                        <label>Parameters</label>
                        <select
                          disabled={section.isDisabled}
                          value={section.parameter[0] || ""}
                          onChange={(e) =>
                            handleWeaklyValuesChange(index, 0, e.target.value)
                          }
                        >
                          <option value="">Select Value</option>
                          {Array.from({ length: 100 }, (_, i) => (
                            <option key={i + 1} value={i + 1}>
                              {i + 1}{section.measure==="Completion"?"days":"%"}
                            </option>
                          ))}
                        </select>
                      </div>

                      {section.condition === "Between" && (
                        <div className={style.inputContainer}>
                          <label>Parameters</label>
                          <select
                            disabled={section.isDisabled}
                            value={section.parameter[1] || ""}
                            onChange={(e) =>
                              handleWeaklyValuesChange(index, 1, e.target.value)
                            }
                          >
                            <option value="">Select Value</option>
                            {Array.from({ length: 100 }, (_, i) => (
                              <option key={i + 1} value={i + 1}>
                                {i + 1}{section.measure==="Completion"?"days":"%"}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}

                      <div className={style.inputContainer}>
                        <label>Reward</label>
                        <select
                          disabled={section.isDisabled}
                          value={section.then}
                          onChange={(e) =>{
                            const selectedOption =
                            e.target.options[e.target.selectedIndex];
                            handleWeaklyInputChange(
                              index,
                              "then",
                              e.target.value,
                              selectedOption.text
                            )
                          }
                          }
                        >
                          <option value="">Select Reward</option>
                          {badgeList&&badgeList.length>0&&badgeList.filter((badge) => badge.type === "Weekly Workout").map((badge)=>(
                            <option key={badge.badge_id} value={badge.badge_id}>{badge.name}</option>
                          ))}
                        </select>
                      </div>

                      <div className={style.buttonContainer}>
                        {section.operator === "" && !section.isDisabled && (
                          <button
                            onClick={() =>
                              handleWeaklyOperationClick(index, "AND")
                            }
                          >
                            AND
                          </button>
                        )}
                        {section.operator === "" && !section.isDisabled && (
                          <button
                            onClick={() =>
                              handleWeaklyOperationClick(index, "OR")
                            }
                          >
                            OR
                          </button>
                        )}
                        {section.operator &&
                          weaklyData.length > 1 &&
                          !section.isDisabled && (
                            <button
                              style={{ background: "transparent" }}
                              onClick={() =>
                                handleBadgeDeleteSection(
                                  index,
                                  "weakly_workout"
                                )
                              }
                            >
                              <IoCloseOutline size={20} />
                            </button>
                          )}
                      </div>
                    </div>
                    <div className={style.operator}>
                      {section.operator && (
                        <p className={style.operationText}>
                          {section.operator}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      :<div className={style.selectionInfo}>
        <p>Please select a Organization</p></div>}
      {createPopup && (
        <>
          <div className={style.popupOverlay}></div>
          <div className={style.popup}>
            <div className={style.popupHead}>
              <p>Create Popup</p>
            </div>
            <div className={style.popupContent}>
              <div className={style.popupInput}>
                <label>Name</label>
                <input
                  name="name"
                  placeholder="Name"
                  value={createFields.name}
                  onChange={handleChange}
                />
              </div>
              <div className={style.popupInput}>
                <label>Description</label>
                <textarea
                  name="description"
                  rows={3}
                  placeholder="Description"
                  value={createFields.description}
                  onChange={handleChange}
                />
              </div>
              <div className={style.popupInput}>
                <label>Type</label>
                <select
                  name="type"
                  value={createFields.type}
                  onChange={handleChange}
                >
                  <option disabled value="">
                    Select type
                  </option>
                  <option value="Topic and module">Topic and module</option>
                  <option value="Daily Drill">Daily Drill</option>
                  <option value="Weekly Workout">Weekly Workout</option>
                </select>
              </div>
              <div className={style.popupInput}>
                <label>Default image</label>
                {createFields.default_img ? (
                  <img src={createFields.default_img} alt="defaultImage" />
                ) : (
                  <input
                    name="default_img"
                    type="file"
                    onChange={handleFileChange}
                    accept="image/*"
                  />
                )}
              </div>
              <div className={style.popupInput}>
                <label>Active image</label>
                {createFields.active_img ? (
                  <img src={createFields.active_img} alt="activeImage" />
                ) : (
                  <input
                    name="active_img"
                    type="file"
                    onChange={handleFileChange}
                    accept="image/*"
                  />
                )}
              </div>
            </div>
            <div className={style.popupButton}>
              <button
                className={style.cancel}
                onClick={() =>{ 
                  setCreatePopup(false);
                  setCreateFields({
                    name: "",
                    description: "",
                    type: "",
                    default_img: "",
                    active_img: "",
                  });
                }}
              >
                Cancel
              </button>
              <button className={style.create} onClick={createBadge}>
                Create
              </button>
            </div>
          </div>
        </>
      )}

      {editPopup && (
        <>
          <div className={style.popupOverlay}></div>
          <div className={style.popup}>
            <div className={style.popupHead}>
              <p>Edit Popup</p>
            </div>
            <div className={style.popupContent}>
              <div className={style.popupInput}>
                <label>Name</label>
                <input
                  name="name"
                  placeholder="Name"
                  value={editFields.name}
                  onChange={handleEditChange}
                />
              </div>
              <div className={style.popupInput}>
                <label>Description</label>
                <textarea
                  name="description"
                  rows={3}
                  placeholder="Description"
                  value={editFields.description}
                  onChange={handleEditChange}
                />
              </div>
              <div className={style.popupInput}>
                <label>Type</label>
                <select
                 value={editFields.type}
                 onChange={handleEditChange}
                 name="type">
                  <option disabled value="">
                    Select type
                  </option>
                  <option value="Topic and module">Topic and module</option>
                  <option value="Daily Drill">Daily Drill</option>
                  <option value="Weekly Workout">Weekly Workout</option>
                </select>
              </div>
              <div className={style.popupInput}>
                <label>Default image</label>
                {editFields.default_img ? (
                  <>
                  <img
                    src={editFields.default_img}
                    alt="defaultImage"
                    onClick={() => editFileInputRef.current.click()} 
                    style={{ cursor: "pointer" }} 
                  />
                  <input
                    name="default_img"
                    type="file"
                    onChange={handleEditFileChange}
                    accept="image/*"
                    ref={editFileInputRef} 
                    style={{ display: "none" }} 
                  />
                </>
                ) : (
                  <input
                    name="default_img"
                    type="file"
                    onChange={handleEditFileChange}
                    accept="image/*"
                  />
                )}
              </div>
              <div className={style.popupInput}>
                <label>Active image</label>
                {editFields.active_img ? (
                 <>
                 <img
                   src={editFields.active_img}
                   alt="defaultImage"
                   onClick={() => editFileInputRef.current.click()} 
                   style={{ cursor: "pointer" }} 
                 />
                 <input
                   name="active_img"
                   type="file"
                   onChange={handleEditFileChange}
                   accept="image/*"
                   ref={editFileInputRef} 
                   style={{ display: "none" }} 
                 />
               </>
                ) : (
                  <input
                    name="active_img"
                    type="file"
                    onChange={handleEditFileChange}
                    accept="image/*"
                  />
                )}
              </div>
            </div>
            <div className={style.popupButton}>
              <button
                className={style.cancel}
                onClick={() => {setEditPopup(false)
                  setEditFields({})
                }}
              >
                Cancel
              </button>
              <button className={style.create} onClick={updateBadge}>Update</button>
            </div>
          </div>
        </>
      )}

      {deletePopup && (
        <>
          <div className={style.popup}>
            <div className={style.popupHead}>
              <p>Delete Popup</p>
            </div>
            <div className={style.popupContent}>
              <div className={style.popupText}>
                <p>Are you sure you want to delete?..</p>
              </div>
            </div>
            <div className={style.popupButton}>
              <button
                className={style.cancel}
                onClick={() => setDeletePopup(false)}
              >
                Cancel
              </button>
              <button className={style.create}>Update</button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Configuration;
