import React, { useEffect, useState } from "react";
import style from "./SessionEvent.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { baseUrl } from "../Url";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
import Paper from "@mui/material/Paper";
import { HiOutlineViewGrid } from "react-icons/hi";

const SessionEvent = () => {
  const location = useLocation();
  const state = location.state;
  console.log(state);
  const navigate = useNavigate();

  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page
  const [pageData, setPageData] = useState([]);

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page
  };

  const [eventData, setEventData] = useState([]);

  const [eventPopupShow, setEventPopupShow] = useState(false);
  const [eventDate, setEventDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [eventTitle, setEventTitle] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const [eventEditPopupShow, setEventEditPopupShow] = useState(false);
  const [eventEditDate, setEventEditDate] = useState(null);
  const [eventEditTitle, setEventEditTitle] = useState("");
  const [eventEditDescription, setEventEditDescription] = useState("");
  const [editStartTime, setEditStartTime] = useState("");
  const [editEndTime, setEditEndTime] = useState("");
  const [eventId, setEventId] = useState("");

  const [eventDeletePopupShow, setEventDeletePopupShow] = useState(false);

  const convertToCustomFormat = (time, eventDate) => {
    const localDateTime = new Date(`${eventDate}T${time}`);
    const year = localDateTime.getUTCFullYear();
    const month = String(localDateTime.getUTCMonth() + 1).padStart(2, "0");
    const day = String(localDateTime.getUTCDate()).padStart(2, "0");
    const hours = String(localDateTime.getUTCHours()).padStart(2, "0");
    const minutes = String(localDateTime.getUTCMinutes()).padStart(2, "0");
    const seconds = String(localDateTime.getUTCSeconds()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };

  const parseTime = (dateTime) => {
    const date = new Date(dateTime);
    const hours = String(date.getHours()).padStart(2, "0"); // Format hours to 2 digits
    const minutes = String(date.getMinutes()).padStart(2, "0"); // Format minutes to 2 digits
    return `${hours}:${minutes}`; // Return in HH:MM format
  };

  const handleStartTime = (e) => {
    const selectedStartTime = e.target.value;
    const currentDateTime = new Date();
    const selectedDateTime = new Date(`${eventDate}T${selectedStartTime}`);

    if (selectedDateTime <= currentDateTime) {
      setStartTime("");
    } else {
      setStartTime(selectedStartTime);
    }
  };

  const handleEndTime = (e) => {
    const selectedEndTime = e.target.value;
    if (eventDate && selectedEndTime <= startTime) {
      setEndTime("");
    } else {
      setEndTime(selectedEndTime);
    }
  };

  const handleEditStartTime = (e) => {
    const selectedStartTime = e.target.value;
    const currentDateTime = new Date();
    const selectedDateTime = new Date(`${eventEditDate}T${selectedStartTime}`);

    if (selectedDateTime <= currentDateTime) {
      setEditStartTime("");
    } else {
      setEditStartTime(selectedStartTime);
    }
  };

  const handleEditEndTime = (e) => {
    const selectedEndTime = e.target.value;
    if (eventEditDate && selectedEndTime <= startTime) {
      setEditEndTime("");
    } else {
      setEditEndTime(selectedEndTime);
    }
  };

  async function eventGet() {
    await fetch(`${baseUrl}/session/events-list`, {
      method: "POST",
      body: JSON.stringify({
        session_id: state?.session,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "event list");
        setEventData(data.data);
      });
  }

  async function eventCreation() {
    const startUTC = convertToCustomFormat(startTime, eventDate);
    const endUTC = convertToCustomFormat(endTime, eventDate);
    await fetch(`${baseUrl}/session/add-event`, {
      method: "POST",
      body: JSON.stringify({
        session_id: state.session,
        event_name: eventTitle,
        description: eventDescription,
        StartDateTime: startUTC,
        EndDateTime: endUTC,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setEventPopupShow(false);
          setEventDate(null);
          setStartTime("");
          setEndTime("");
          setEventTitle("");
          setEventDescription("");
          eventGet();
        }
      });
  }

  async function eventEdit() {
    const startUTC = convertToCustomFormat(editStartTime, eventEditDate);
    const endUTC = convertToCustomFormat(editEndTime, eventEditDate);
    await fetch(`${baseUrl}/session/event-update`, {
      method: "POST",
      body: JSON.stringify({
        session_event_id: eventId,
        session_id: state?.session,
        event_name: eventEditTitle,
        description: eventEditDescription,
        StartDateTime: startUTC,
        EndDateTime: endUTC,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          eventGet();
          setEventEditPopupShow(false);
        }
      });
  }

  async function eventDelete() {
    await fetch(`${baseUrl}/session/event-delete`, {
      method: "POST",
      body: JSON.stringify({
        session_event_id: eventId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          eventGet();
          setEventDeletePopupShow(false);
        }
      });
  }

  useEffect(() => {
    if (state.session) {
      eventGet();
    }
  }, [state.session]);

  useEffect(() => {
    if (eventData) {
      const paginatedData = eventData?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
      setPageData(paginatedData);
    }
  }, [eventData, rowsPerPage, page]);

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.headerText}>
          <p>Event List</p>
        </div>
        <div className={style.headerButton}>
          <button
            onClick={() => {
              setEventPopupShow(true);
            }}
          >
            Create event
          </button>
        </div>
      </div>
      <div className={style.eventList}>
        <Paper sx={{ height: "100%", width: "100%" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow sx={{ background: "#ededed" }}>
                <TableCell>Event Title</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Start Time</TableCell>
                <TableCell>End Time</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pageData.map((list, i) => {
                return (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {list.event_name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {new Date(list.StartDateTime).toISOString().split("T")[0]}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {parseTime(list.StartDateTime)}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {parseTime(list.EndDateTime)}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Tooltip title="View">
                        <IconButton>
                          <HiOutlineViewGrid
                            className={style.viewButton}
                            onClick={() => {
                              navigate("/event/detail", {
                                state: { eventId: list.session_event_id },
                              });
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit">
                        <IconButton>
                          <BiEdit
                            className={style.editButton}
                            onClick={() => {
                              setEventEditPopupShow(true);
                              setEventEditTitle(list.event_name);
                              setEventEditDescription(list.description);
                              setEventEditDate(
                                new Date(list.created_date)
                                  .toISOString()
                                  .split("T")[0]
                              );
                              setEditStartTime(parseTime(list.StartDateTime));
                              setEditEndTime(parseTime(list.EndDateTime));
                              setEventEditDescription(list.description);
                              setEventId(list.session_event_id);
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton>
                          <RiDeleteBinLine
                            className={style.deleteButton}
                            onClick={() => {
                              setEventDeletePopupShow(true);
                              setEventId(list.session_event_id);
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={eventData?.length} // Total number of rows
            page={page} // Current page
            onPageChange={handleChangePage} // Handle page change
            rowsPerPage={rowsPerPage} // Rows per page
            onRowsPerPageChange={handleChangeRowsPerPage} // Handle rows per page change
            rowsPerPageOptions={[5, 10, 25]} // Options for rows per page
          />
        </Paper>
      </div>

      {eventPopupShow && (
        <>
          <div
            className={style.overlay}
            onClick={() => {
              setEventPopupShow(false);
              setEventDate(null);
              setStartTime("");
              setEndTime("");
              setEventTitle("");
              setEventDescription("");
            }}
          ></div>
          <div className={style.eventPopup}>
            <div className={style.eventPopupHeader}>
              <p>Create your event</p>
              <IoMdClose
                onClick={() => {
                  setEventPopupShow(false);
                  setEventDate(null);
                  setStartTime("");
                  setEndTime("");
                  setEventTitle("");
                  setEventDescription("");
                }}
              />
            </div>
            <div className={style.eventPopupSection}>
              <div className={style.eventInputContainer}>
                <div className={style.eventInput}>
                  <label htmlFor="e-name">Event title</label>
                  <input
                    type="text"
                    name=""
                    id="e-name"
                    placeholder="Enter the event title"
                    value={eventTitle}
                    onChange={(e) => setEventTitle(e.target.value)}
                  />
                </div>
                <div className={style.eventInput}>
                  <label htmlFor="e-date">Date</label>
                  <input
                    type="date"
                    id="e-date"
                    placeholder="Select the date"
                    value={eventDate}
                    onChange={(e) => setEventDate(e.target.value)}
                    min={new Date().toISOString().split("T")[0]}
                  />
                </div>
              </div>
              <div className={style.eventInputContainer}>
                <div className={style.eventInput}>
                  <label htmlFor="s-time">Start Time</label>
                  <input
                    type="time"
                    name="startTime"
                    id="startTime"
                    required
                    placeholder="Start Time"
                    value={startTime}
                    onChange={handleStartTime}
                  />
                </div>

                <div className={style.eventInput}>
                  <label htmlFor="e-time">End Time</label>
                  <input
                    type="time"
                    name="endTime"
                    id="endTime"
                    required
                    placeholder="End Time"
                    value={endTime}
                    onChange={handleEndTime}
                  />
                </div>
              </div>
              <div className={style.eventInputContainer}>
                <div className={style.eventSecondInput}>
                  <label htmlFor="e-description">Description</label>
                  <textarea
                    name=""
                    id="e-description"
                    placeholder="Write something.."
                    value={eventDescription}
                    onChange={(e) => {
                      setEventDescription(e.target.value);
                    }}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className={style.eventButtons}>
              <button
                onClick={() => {
                  setEventPopupShow(false);
                  setEventDate(null);
                  setStartTime("");
                  setEndTime("");
                  setEventTitle("");
                  setEventDescription("");
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  eventCreation();
                }}
              >
                Create
              </button>
            </div>
          </div>
        </>
      )}

      {eventEditPopupShow && (
        <>
          <div
            className={style.overlay}
            onClick={() => {
              setEventEditPopupShow(false);
            }}
          ></div>
          <div className={style.eventPopup}>
            <div className={style.eventPopupHeader}>
              <p>Create your event</p>
              <IoMdClose
                onClick={() => {
                  setEventEditPopupShow(false);
                }}
              />
            </div>
            <div className={style.eventPopupSection}>
              <div className={style.eventInputContainer}>
                <div className={style.eventInput}>
                  <label htmlFor="e-name">Event title</label>
                  <input
                    type="text"
                    name=""
                    id="e-name"
                    placeholder="Enter the event title"
                    value={eventEditTitle}
                    onChange={(e) => setEventEditTitle(e.target.value)}
                  />
                </div>
                <div className={style.eventInput}>
                  <label htmlFor="e-date">Date</label>
                  <input
                    type="date"
                    id="e-date"
                    placeholder="Select the date"
                    value={eventEditDate}
                    onChange={(e) => setEventEditDate(e.target.value)}
                    min={eventEditDate}
                  />
                </div>
              </div>
              <div className={style.eventInputContainer}>
                <div className={style.eventInput}>
                  <label htmlFor="s-time">Start Time</label>
                  <input
                    type="time"
                    name="startTime"
                    id="startTime"
                    required
                    placeholder="Start Time"
                    value={editStartTime}
                    onChange={handleEditStartTime}
                  />
                </div>

                <div className={style.eventInput}>
                  <label htmlFor="e-time">End Time</label>
                  <input
                    type="time"
                    name="endTime"
                    id="endTime"
                    required
                    placeholder="End Time"
                    value={editEndTime}
                    onChange={handleEditEndTime}
                  />
                </div>
              </div>
              <div className={style.eventInputContainer}>
                <div className={style.eventSecondInput}>
                  <label htmlFor="e-description">Description</label>
                  <textarea
                    name=""
                    id="e-description"
                    placeholder="Write something.."
                    value={eventEditDescription}
                    onChange={(e) => {
                      setEventEditDescription(e.target.value);
                    }}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className={style.eventButtons}>
              <button
                onClick={() => {
                  setEventEditPopupShow(false);
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  eventEdit();
                }}
              >
                Edit
              </button>
            </div>
          </div>
        </>
      )}

      {eventDeletePopupShow && (
        <>
          <div
            onClick={() => {
              setEventDeletePopupShow(false);
            }}
            className={style.overlay}
          ></div>
          <div className={style.deletePopup}>
            <div className={style.deleteClose}>
              <AiOutlineClose
                onClick={() => {
                  setEventDeletePopupShow(false);
                }}
              />
            </div>
            <div className={style.deleteSection}>
              <div className={style.popupText}>
                <p>Are you sure you want to delete ?</p>
              </div>
              <div className={style.popupDelete}>
                <button
                  className={style.orgDelete}
                  onClick={() => {
                    eventDelete();
                  }}
                >
                  Delete
                </button>
                <button
                  className={style.orgDeleteCancel}
                  onClick={() => {
                    setEventDeletePopupShow(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SessionEvent;
