import React, { useEffect, useState } from "react";
import style from "./Report.module.css";
import { FaChevronDown } from "react-icons/fa6";
import { baseUrl } from "../Url";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const Report = () => {
  const key = JSON.parse(localStorage.getItem("access-token"));
  const userId = localStorage.getItem("adminUserId");

  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(0);

  const [selectedOrg, setSelectedOrg] = useState("");
  const [orgData, setOrgData] = useState([]);
  const [orgActivate, setOrgActivate] = useState(true);
  const [userListShow, setUserListShow] = useState(false);
  const [tabFilter, setTabFilter] = useState("old");

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [courseDataList, setCourseDataList] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [userListData, setUserListData] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [eventListData, setEventListData] = useState([]);

  const [apiMessage, setApiMessage] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageData, setPageData] = useState([]);

  const handleTabSwitch = (tab, filter) => {
    setSelectedTab(tab);
    setTabFilter(filter);
    setUserListShow(false);
    setSelectedOrg("");
    setFromDate("");
    setToDate("");
    setSelectedCourse("");
    setSelectedUsers([]);
    setEventListData([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCheckboxChange = (userId) => {
    setSelectedUsers((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId]
    );
  };

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };

  async function orgGet() {
    await fetch(`${baseUrl}/org/get/admin/per_users`, {
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "orgData");
        setOrgData(data.data);
      });
  }

  async function courseGet() {
    await fetch(`${baseUrl}/org/course-list`, {
      method: "POST",
      body: JSON.stringify({
        org_id: selectedOrg,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setCourseDataList(data);
      });
  }

  async function userGet() {
    await fetch(`${baseUrl}/list/org-user`, {
      method: "POST",
      body: JSON.stringify({
        org_id: selectedOrg,
        search: "",
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setUserListData(data.data);
      });
  }

  async function eventGet() {
    const F_date = fromDate ? formatDate(fromDate) : "";
    const T_date = toDate ? formatDate(toDate) : "";

    await fetch(`${baseUrl}/session/events-report`, {
      method: "POST",
      body: JSON.stringify({
        org_id: selectedOrg,
        filter: tabFilter,
        courses: selectedCourse ? selectedCourse : [],
        from: F_date,
        to: T_date,
        user_ids: selectedUsers,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "event list");
        if (data.status === true) {
          setEventListData(data.data);
          setApiMessage(""); // Clear any previous error message
        } else {
          setEventListData([]); // Clear table data
          setApiMessage(data.message); // Set the message from the response
        }
      });
  }

  useEffect(() => {
    if (eventListData) {
      const paginatedData = eventListData?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
      setPageData(paginatedData);
    }
  }, [eventListData, rowsPerPage, page]);

  useEffect(() => {
    if (userId) {
      orgGet();
    }
  }, [userId]);

  useEffect(() => {
    if (selectedOrg) {
      eventGet();
    }
  }, [selectedOrg, selectedCourse, selectedUsers, fromDate, toDate, tabFilter]);

  useEffect(() => {
    if (selectedOrg) {
      courseGet();
      userGet();
    }
  }, [selectedOrg]);

  useEffect(() => {
    console.log(fromDate);
  }, [fromDate]);

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.headerText}>
          <p>Attendance Report</p>
        </div>
      </div>
      <div className={style.reportDetail}>
        <div className={style.tabHeader}>
          <div className={style.headerLeft}>
            <div
              className={
                selectedTab === 0 ? style.activeTabValue : style.tabValue
              }
              onClick={() => {
                handleTabSwitch(0, "old");
              }}
            >
              <p>Past</p>
            </div>
            <div
              className={
                selectedTab === 1 ? style.activeTabValue : style.tabValue
              }
              onClick={() => {
                handleTabSwitch(1, "new");
              }}
            >
              <p>Upcoming</p>
            </div>
          </div>
          <div className={style.headerRight}>
            <select
              name="org"
              id="org"
              value={selectedOrg}
              onChange={(e) => {
                setSelectedOrg(e.target.value);
                setOrgActivate(false);
              }}
            >
              <option value="">Select organisations</option>
              {orgData &&
                orgData.length > 0 &&
                orgData.map((list, i) => {
                  return (
                    <option key={i} value={list.org_id}>
                      {list.org_name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div className={style.tabBody}>
          <div className={style.selectionSection}>
            <div className={style.selectionInput}>
              <label htmlFor="s-date">From</label>
              <input
                type="date"
                name=""
                id=""
                disabled={orgActivate}
                value={fromDate}
                onChange={(e) => {
                  setFromDate(e.target.value);
                }}
              />
            </div>
            <div className={style.selectionInput}>
              <label htmlFor="e-date">To</label>
              <input
                type="date"
                name=""
                id=""
                disabled={orgActivate}
                value={toDate}
                onChange={(e) => {
                  setToDate(e.target.value);
                }}
              />
            </div>
            <div className={style.selectionInput}>
              <label htmlFor="course">Course</label>
              <select
                name="course"
                id="course"
                disabled={orgActivate}
                value={selectedCourse}
                onChange={(e) => {
                  setSelectedCourse(e.target.value);
                }}
              >
                <option value="">Select course</option>
                {courseDataList &&
                  courseDataList.length &&
                  courseDataList.map((list, i) => {
                    return (
                      <option value={list.category_id} key={i}>
                        {list.Name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className={style.selectionInput}>
              <label htmlFor="participant">Participant</label>
              <div className={style.userInputContainer}>
                <div
                  className={style.userInputTop}
                  onClick={() => {
                    if (!orgActivate) {
                      setUserListShow(!userListShow);
                    }
                  }}
                >
                  <p>Select participant</p>
                  <FaChevronDown />
                </div>
                {userListShow && (
                  <div className={style.userInputBottom}>
                    {userListData &&
                      userListData.length > 0 &&
                      userListData.map((list, i) => (
                        <div key={i} className={style.inputBottomValue}>
                          <input
                            type="checkbox"
                            checked={selectedUsers.includes(list.user_id)}
                            onChange={() => handleCheckboxChange(list.user_id)}
                          />
                          <p>{list.user_name}</p>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={style.tabSelectionSection}>
            <Paper sx={{ height: "100%", width: "100%", overflow: "hidden" }}>
              {apiMessage ? (
                <div className={style.errorMessage}>
                  <p>{apiMessage}</p>
                </div>
              ) : (
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Course</TableCell>
                        <TableCell>Session</TableCell>
                        <TableCell>Events</TableCell>
                        <TableCell>Attendees</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {eventListData.map((list, i) => (
                        <TableRow
                          key={i}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {
                              new Date(list.StartDateTime)
                                .toISOString()
                                .split("T")[0]
                            }
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {list.course_name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {list.session_name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {list.event_name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <button
                              onClick={() => {
                                navigate("/event/detail", {
                                  state: { eventId: list.session_event_id },
                                });
                              }}
                            >
                              Attendees
                            </button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              {!apiMessage && (
                <TablePagination
                  component="div"
                  count={eventListData?.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[10, 20, 30]}
                />
              )}
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;
